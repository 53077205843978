// src/pages/auth0_callback.js

import React from "react";
import { auth } from "../utils/auth";
import useComponentDidMount from "../hooks/use-component-did-mount";
import LoadingPage from "../custom-pages/loading-page";

const Auth0CallbackPage = () => {
    useComponentDidMount(() => {
        auth.handleAuthentication();
    });

    return <LoadingPage />;
};

export default Auth0CallbackPage;
